/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  initSmoothScroll();
}

function initSmoothScroll() {
  if (typeof window !== 'undefined') {
    console.log("Window is defined!")
    require('smooth-scroll')('[data-scroll]', {
      easing: 'easeInOutCubic',
      speed: 300,
      speedAsDuration: true,
      updateURL: false
    });
  }
}
